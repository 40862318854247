import * as React from "react"
import './NhsAutocompleteSuggestions.scss';

export default function NhsAutocompleteSuggestions({ inputValue, suggestions, handleOnClick, ...props }) {
    return (
        <>
            {suggestions ?
                <>
                    {suggestions.map((suggestion, index) => (
                        <div className="siteRows" key={index} onClick={() => handleOnClick(props.name, suggestion)}>
                            <HighlightMatch suggestion={suggestion.Name} inputValue={inputValue} />
                        </div>
                    ))}
                </>
                : null
            }
        </>
    );
}

function HighlightMatch({ suggestion, inputValue }) {
    if (suggestion) {
        const index = suggestion?.indexOf(inputValue);
        if (index === -1) return <>{suggestion}</>;

        const beforeMatch = suggestion.slice(0, index);
        const match = suggestion.slice(index, index + inputValue.length);
        const afterMatch = suggestion.slice(index + inputValue.length);

        return (
            <>
                {beforeMatch}
                <strong>{match}</strong>
                {afterMatch}
            </>
        );
    }
}