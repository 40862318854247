import React, { FC, useEffect } from 'react';
import { RoleIds } from "../user.enums";
import AccessControl from "../../../_shared/components/accessControl/AccessControl";
import { BackLink, Button, InsetText } from "nhsuk-react-components";
import { ReportDateSelection } from '../../reports/ReportDateSelection';
import NhsCheckboxes from '../../../_shared/components/form/NhsCheckboxes';
import ReportSummary from '../../reports/ReportSummary';
import { FormateDate, formatStringList } from '../../../_shared/shared.functions';
import reportsService, { SiteName, VaccineProgramName } from '../../../_shared/services/reports/reports.service';
import { useUser } from '../UserProvider';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../../_shared/components/Loading';

const fetchSites = async (organisationId: number): Promise<SiteName[]> => {
    try {
        return await reportsService.getAllSites$(organisationId);
    } catch (error) {
        console.error("Failed to fetch sites");
        return [];
    }
};

const fetchVaccinePrograms = async (): Promise<VaccineProgramName[]> => {
    try {
        return await reportsService.getAllVaccinationPrograms$();
    } catch (error) {
        console.error("Failed to fetch vaccine programs");
        return [];
    }
};

export enum ReportsPages {
    CreateReport = "Report",
    ChooseDate = "Report dates",
    ChooseVaccine = "Report vaccines",
    ChooseSite = "Report sites",
    ReportSummary = "Report check and confirm",
    ReportDownload = "Report ready",
}

export interface SelectedDates {
    dateRangeOption: string;
    fromDate: Date;
    toDate: Date;
}

export const ReportsHome: FC = () => {
    const [currentPage, setCurrentPage] = React.useState<ReportsPages>(ReportsPages.CreateReport);
    const currentUser = useUser();
    const navigate = useNavigate();
    const [selectedDates, setSelectedDates] = React.useState<SelectedDates | null>(null);
    const [selectedVaccines, setSelectedVaccines] = React.useState<string[] | null>(null);
    const [selectedSites, setSelectedSites] = React.useState<string[] | null>(null);
    const [vaccinationOptions, setVaccinationOptions] = React.useState<VaccineProgramName[]>([]);
    const [sitesOptions, setSitesOptions] = React.useState<SiteName[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isDisabled, setDisabled] = React.useState<boolean>(false);
    const [reportUrl, setReportUrl] = React.useState<string | null>(null);


    const buildDateString = (date: Date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // getMonth() returns 0-based index, so we add 1
        const day = date.getDate();

        return `${year}-${month}-${day}`;
    };
    const generateReport = async () => {
        try {
            const reportBlobUrl = await reportsService.generateReport({
                siteIds: sitesOptions.filter(site => selectedSites?.includes(site.Name)).map(site => site.SiteId),
                vaccinationPrograms: vaccinationOptions.filter(vaccine => selectedVaccines?.includes(vaccine.Name)).map(vaccine => vaccine.VaccineProgramId),
                startDate: buildDateString(selectedDates!.fromDate),
                endDate: buildDateString(selectedDates!.toDate)
            });
            setReportUrl(reportBlobUrl);
        } catch (error) {
            console.error("Error downloading report", error);
        }
    };

    useEffect(() => {
        if (currentUser && currentUser.OrganisationId) {
            setIsLoading(true);
            Promise.all([
                fetchSites(currentUser.OrganisationId),
                fetchVaccinePrograms()
            ]).then(([sites, vaccinePrograms]) => {
                setSitesOptions(sites);
                setVaccinationOptions(vaccinePrograms);
                setIsLoading(false);
                setDisabled(sites.length <= 0 || vaccinePrograms.length <= 0);
            }).catch(() => {
                navigate('/error');
            });
        }
    }, [currentUser]);

    useEffect(() => {
        if (currentPage === ReportsPages.CreateReport) {
            setSelectedDates(null);
            setSelectedVaccines(null);
            setSelectedSites(null);
        }
        let titleEnd = "RAVS";
        document.title = currentPage + " - " + titleEnd;
    }, [currentPage])

    const handlePageChange = (isForward: boolean) => {
        switch (currentPage) {
            case ReportsPages.ChooseDate:
                if (isForward) {
                    setCurrentPage(ReportsPages.ChooseVaccine);
                } else {
                    setCurrentPage(ReportsPages.CreateReport);
                }
                break;
            case ReportsPages.ChooseVaccine:
                if (isForward) {
                    setCurrentPage(ReportsPages.ChooseSite);
                } else {
                    setCurrentPage(ReportsPages.ChooseDate);
                }
                break;
            case ReportsPages.ChooseSite:
                if (isForward) {
                    setCurrentPage(ReportsPages.ReportSummary);
                } else {
                    setCurrentPage(ReportsPages.ChooseVaccine);
                }
                break;
            case ReportsPages.ReportSummary:
                if (isForward) {
                    setCurrentPage(ReportsPages.ReportDownload);
                } else {
                    setCurrentPage(ReportsPages.ChooseSite);
                }
                break;
            case ReportsPages.ReportDownload:
                setCurrentPage(ReportsPages.ReportSummary);
                break;
            default:
                setCurrentPage(ReportsPages.CreateReport);
                break;
        }
    };

    const downloadReport = () => {
        reportsService.downloadReport(reportUrl!);
        setCurrentPage(ReportsPages.CreateReport);
    }

    return (
        <AccessControl requiredRoles={[RoleIds.Administrator, RoleIds.LeadAdministrator]}>
            <div className="nhsuk-grid-row">
                <div className='nhsuk-grid-column-two-thirds'>
                    {(currentPage !== ReportsPages.CreateReport && currentPage !== ReportsPages.ReportDownload)
                        && <BackLink
                            asElement="button"
                            onClick={() => {
                                handlePageChange(false)
                            }}
                        >
                            Back
                        </BackLink>}
                    {currentPage === ReportsPages.CreateReport && <div>
                        <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
                            <h1 className="nhsuk-fieldset__heading">
                                Reports
                            </h1>
                        </legend>
                        <div className="nhsuk-hint mb-3" id="record-a-service-h1-hint">
                            <p>Create and download reports</p>
                        </div>
                        {!isDisabled && <InsetText>
                            <p>Reports may take longer to process if you choose more data or a wider date range.</p>
                        </InsetText>}
                        {isDisabled && <InsetText>
                            <p>No vaccination data to report on.</p>
                        </InsetText>}
                        {isLoading && <Loading />}
                        {!isLoading && <Button disabled={isDisabled}
                            onClick={() => {
                                setCurrentPage(ReportsPages.ChooseDate);
                            }}
                        >
                            Create report
                        </Button>}
                    </div>}
                    {currentPage === ReportsPages.ChooseDate && <div>
                        <ReportDateSelection handlePageChange={handlePageChange} setSelectedDate={setSelectedDates} selectedDates={selectedDates} />
                    </div>}
                    {currentPage === ReportsPages.ChooseVaccine && <div>
                        <NhsCheckboxes
                            title='Choose vaccine'
                            checkboxes={vaccinationOptions.map(vaccine => vaccine.Name)}
                            selectedCheckboxes={selectedVaccines}
                            setSelectedCheckboxes={setSelectedVaccines}
                            handlePageChange={handlePageChange}
                            errorMessage='Select vaccines' />

                    </div>}
                    {currentPage === ReportsPages.ChooseSite && <div>
                        <NhsCheckboxes
                            title='Choose site'
                            checkboxes={sitesOptions.map(site => site.Name)}
                            selectedCheckboxes={selectedSites}
                            setSelectedCheckboxes={setSelectedSites}
                            handlePageChange={handlePageChange}
                            errorMessage='Select sites' />
                    </div>}
                    {currentPage === ReportsPages.ReportSummary && <div>
                        <ReportSummary
                            handlePageChange={handlePageChange}
                            dates={selectedDates}
                            vaccines={selectedVaccines}
                            sites={selectedSites}
                            setCurrentPage={setCurrentPage}
                            generateReport={generateReport}
                        />
                    </div>}
                    {currentPage === ReportsPages.ReportDownload && <div>
                        <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
                            <h1 className="nhsuk-fieldset__heading">
                                Report is ready
                            </h1>
                        </legend>
                        <div className="nhsuk-hint mb-3" id="record-a-service-h1-hint">
                            {['yesterday', 'today'].includes(selectedDates.dateRangeOption) ? 
                                <p>{`You have created a report dated ${FormateDate(selectedDates.fromDate)} 
                                for ${formatStringList(selectedVaccines)} at ${formatStringList(selectedSites)}`}</p> :
                                <p>{`You have created a report from ${FormateDate(selectedDates.fromDate)} 
                                to ${FormateDate(selectedDates.toDate)} for ${formatStringList(selectedVaccines)} at ${formatStringList(selectedSites)}`}</p>}
                        </div>
                        <Button
                            onClick={downloadReport}
                        >
                            Download report
                        </Button>

                    </div>}
                </div>
            </div>
        </AccessControl>
    );
}