import { useEffect } from 'react';
import { useCookie } from '../../_layout/cookie/CookieProvider';
import { Category } from '../analytics.models';
import { RadioValues } from '../../../_shared/shared.enums';

export default function useAnalytics(category: Category) {
  const { cookieConsent } = useCookie();

  useEffect(() => {
    if (cookieConsent === RadioValues.true) {
      let pageName = "nhs:ravs:service";
      if (category?.primaryCategory) pageName += `:${category?.primaryCategory}`;
      if (category?.subCategory1) pageName += `:${category?.subCategory1}`;
      if (category?.subCategory2) pageName += `:${category?.subCategory2}`;
      if (category?.subCategory3) pageName += `:${category?.subCategory3}`;
      
      window.digitalData = {
        page: {
          pageInfo: {
            pageName: pageName
          },
          category: category
        }
      };
    }
  }, [category, category?.primaryCategory, category?.subCategory1, cookieConsent]);
}