import * as React from "react";
import userService from "../user/user.service";
import vaccinatorLocationService from './vaccinator-location.service';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { Button } from 'reactstrap';
import { NhsdOrganisation, VaccinatorLocation } from "./vaccinator-location.models";
import { Redirect } from '../../_shared/shared.functions';
import { CareModelIds, VaccinationLocationPageTitle, VaccinatorLocationAnalyticsPageNames } from './vaccinator-location.enum';
import NhsAutocompleteInput from "../../_shared/components/form/nhs-autocomplete-input/NhsAutocompleteInput";
import NhsAutocompleteSuggestions from "../../_shared/components/form/nhs-autocomplete-input/NhsAutocompleteSuggestions";
import { useDebounceCallback } from 'usehooks-ts'
import { ValidPostcode } from "../../_shared/shared.validation";
import { Category } from "../analytics/analytics.models";
import useAnalytics from "../analytics/hooks/useAnalytics";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
export default function VaccinatorLocationSelect() {
    useAnalytics({ primaryCategory: VaccinatorLocationAnalyticsPageNames.PrimaryCategory } as Category);
    useDocumentTitle(VaccinationLocationPageTitle.Location);
    const [user, setUser] = React.useState(userService.getUser());
    const [userSites, setUserSites] = React.useState(null);
    const [options, setOptions] = React.useState(null);
    const [canShowCareHomeDetails, setCanShowCareHomeDetails] = React.useState(false);
    const [canEnable, setCanEnable] = React.useState(false);
    const [suggestions, setSuggestions] = React.useState([]);
    const [noMatchingRecord, setNoMatchingRecord] = React.useState(false);

    const [nhsdOrganisation, setNhsdOrganisation] = React.useState(null);
    const [noAddressDetailsFound, setNoAddressDetailsFound] = React.useState(false);
    const [enteredManually, setEnteredManually] = React.useState(false);

    React.useEffect(() => {
        const getOptions = async () => {
            setOptions(await vaccinatorLocationService.options$());
        }
        getOptions();

        const getUserSites = async () => {
            setUserSites(await vaccinatorLocationService.getSitesByOrganisationId$(user?.OrganisationId));
        }
        getUserSites();
    }, [user?.OrganisationId]);

    const formik = useFormik({
        initialValues: {
            VaccinatorOrganisationId: user?.OrganisationId,
            VaccinatorOrganisation: user?.Organisation,
            SiteId: "",
            CareModelId: "",
            CareHomeName: "",
            CareHomeOdsCode: "",
            CareHomeAddress: "",
            CareHomePostcode: "",
            DetailsEnteredManually: false
        },

        enableReinitialize: true,

        validationSchema: object().shape({
            SiteId: string().required('Select your site'),
            CareModelId: string().required('Select your care model'),
            CareHomeName: string().when('CareModelId', {
                is: '5' || '6',
                then: schema => schema.required('required').max(250, 'Maximum number of characters is 250')
            }),
            CareHomeOdsCode: string().when('CareModelId', {
                is: '5' || '6',
                then: schema => schema.required('required').max(6, 'Maximum number of characters is 6')
            }),
            CareHomeAddress: string().when('CareModelId', {
                is: '5' || '6',
                then: schema => schema.required('required').max(400, 'Maximum number of characters is 400')
            }),
            CareHomePostcode: string().when('CareModelId', {
                is: '5' || '6',
                then: schema => schema.required('required').test('isValidPostcode', 'Invalid Postcode',
                    function (value) {
                        return ValidPostcode(value)
                    })
            })
        }),

        onSubmit: (values) => {
            let vaccinatorLocation = values as unknown as VaccinatorLocation;
            vaccinatorLocation.VaccinatorOrganisation = user.Organisation;
            vaccinatorLocation.VaccinatorOrganisationId = user.OrganisationId;
            vaccinatorLocation.Site = userSites.find(s => s.Id == values.SiteId)?.Name;
            vaccinatorLocation.SiteId = userSites.find(s => s.Id == values.SiteId)?.Id;
            vaccinatorLocation.CareModel = options.CareModels.find(c => c.Id == values.CareModelId)?.Name;
            vaccinatorLocation.CareModelId = options.CareModels.find(c => c.Id == values.CareModelId)?.Id;

            vaccinatorLocation.CareHome = nhsdOrganisation;
            vaccinatorLocation.CareHomeName = values.CareHomeName;
            vaccinatorLocation.CareHomeOdsCode = values.CareHomeOdsCode;
            vaccinatorLocation.CareHomeAddress = values.CareHomeAddress;
            vaccinatorLocation.CareHomePostcode = values.CareHomePostcode;
            vaccinatorLocationService.setVaccinatorLocation(vaccinatorLocation);
            Redirect('/home')
        }
    })

    const handleSiteChange = (event) => {
        const value = event.target.value;
        setCanEnable(value ? true : false);
    }

    const handleCareModelChange = (event) => {
        const value = event.target.value;
        if (value && (value == CareModelIds.CareHome || value == CareModelIds.ResidentialFacility)) {
            setCanShowCareHomeDetails(true)
        }
        else {
            setCanShowCareHomeDetails(false)
            setNhsdOrganisation(null)
            setEnteredManually(false)
            formik.setFieldValue('CareHomeName', '')
            formik.setFieldValue('DetailsEnteredManually', false)
        }
    }

    const cache = {};
    async function getSuggestions(value) {
        if (cache[value]) {
            return Promise.resolve(cache[value]);
        }

        if (value.length > 2)
            return await vaccinatorLocationService.nhsdOrganisations$(value)
    }

    async function processValue(value: any) {
        var result = await getSuggestions(value);
        cache[value] = result;
        if (result?.length == 0) {
            setNoMatchingRecord(true);
        }
        else {
            setSuggestions(result);
        }
    }

    const [value, setValue] = React.useState('');

    const debounceFn = React.useCallback(useDebounceCallback(processValue, 500), []);
    const memoisedDebouncedFn = React.useMemo(() => debounceFn, [debounceFn]);

    function handleCustomChange(event) {
        setNoMatchingRecord(false)
        const value = event.target.value
        setValue(value)
        memoisedDebouncedFn(value)
    }

    async function getAddressDetails(data: any) {
        return await vaccinatorLocationService.nhsdOrganisation$(data.OrgId) as NhsdOrganisation;
    }

    const getAddressDetailsCallbackFn = React.useCallback(getAddressDetails, []);
    const memoisedGetAddressDetailsCallbackFn = React.useMemo(() => getAddressDetailsCallbackFn, [getAddressDetailsCallbackFn]);
    async function handleSuggestionClick(fieldName, data) {
        setNoAddressDetailsFound(false)
        formik.setFieldValue(fieldName, data.Name.split(',')[0])
        var result = await memoisedGetAddressDetailsCallbackFn(data)
        if (result) {
            let address = result.AddressLine1;
            if (result.AddressLine2) address += ', ' + result.AddressLine2;
            if (result.Town) address += ', ' + result.Town;

            setNhsdOrganisation(result)
            formik.setFieldValue('CareHomeOdsCode', data.OrgId)
            formik.setFieldValue('CareHomeAddress', address)
            formik.setFieldValue('CareHomePostcode', result.PostCode)
        }
        else {
            setNoAddressDetailsFound(true)
            formik.setFieldValue('CareHomeOdsCode', '')
            formik.setFieldValue('CareHomeAddress', '')
            formik.setFieldValue('CareHomePostcode', '')
        }
        setSuggestions([]);
    }

    const onDetailsEnteredManuallyChange = (event) => {
        setEnteredManually(false)
        const value = event.target.checked
        if (value == true) {
            setEnteredManually(true)
        }
    }

    return (
        <>
            <>
                <div className="nhsuk-grid-row mt-5">
                    <div className="offset-1 nhsuk-grid-column-two-thirds">
                        <form onSubmit={formik.handleSubmit}>
                            <fieldset className="nhsuk-fieldset">
                                <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
                                    <h1 className="nhsuk-fieldset__heading">
                                        Your location
                                    </h1>
                                </legend>
                                <div className="nhsuk-hint mb-3" id="vaccinator-location-text-hint">
                                    <p>Please enter your organisation, site and care model to continue.</p>
                                </div>

                                <div className="nhsuk-form-group">
                                    <label htmlFor="VaccinatorOrganisationId" className="nhsuk-label">Organisation</label>

                                    {formik.touched.VaccinatorOrganisationId && formik.errors.VaccinatorOrganisationId ?
                                        <span className="nhsuk-error-message" id="VaccinatorOrganisationIdError">
                                            <span className="nhsuk-u-visually-hidden">Error:</span> {formik.errors.VaccinatorOrganisationId}
                                        </span> : null
                                    }

                                    <select id="VaccinatorOrganisationId"
                                        name="VaccinatorOrganisationId"
                                        aria-label="Your organisation pre populated"
                                        disabled
                                        aria-disabled="true"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className='nhsuk-select'>
                                        <option value={formik.initialValues.VaccinatorOrganisationId}>{formik.initialValues.VaccinatorOrganisation}</option>
                                    </select>
                                </div>

                                <div className="nhsuk-form-group">
                                    <label htmlFor="SiteId" className="nhsuk-label">Site</label>

                                    {formik.touched.SiteId && formik.errors.SiteId ?
                                        <span className="nhsuk-error-message" id="SiteIdError">
                                            <span className="nhsuk-u-visually-hidden">Error:</span> {formik.errors.SiteId}
                                        </span> : null
                                    }

                                    <select id="SiteId"
                                        name="SiteId"
                                        aria-label="Select your site"
                                        onChange={(e) => { handleSiteChange(e); formik.handleChange(e); }}
                                        onBlur={(e) => { handleSiteChange(e); formik.handleBlur(e);  }}
                                        className='nhsuk-select'>
                                        <option value="">--Please select--</option>
                                        {userSites && userSites.map(u => <option key={u.Id} value={u.Id}> {u.Name} </option>)}
                                    </select>
                                </div>

                                <div className="nhsuk-form-group">
                                    <label htmlFor="CareModelId" className="nhsuk-label">Care model</label>

                                    {formik.touched.CareModelId && formik.errors.CareModelId ?
                                        <span className="nhsuk-error-message" id="CareModelIdError">
                                            <span className="nhsuk-u-visually-hidden">Error:</span> {formik.errors.CareModelId}
                                        </span> : null
                                    }

                                    <select id="CareModelId"
                                        name="CareModelId"
                                        disabled={!canEnable}
                                        aria-disabled={!canEnable}
                                        aria-label="Select your care model"
                                        onChange={(e) => { handleCareModelChange(e); formik.handleChange(e); }}
                                        onBlur={(e) => { handleCareModelChange(e); formik.handleBlur(e); }}
                                        className='nhsuk-select'>
                                        <option value="">--Please select--</option>
                                        {options?.CareModels && options?.CareModels.map(u => <option key={u.Id} value={u.Id}> {u.Name} </option>)}
                                    </select>
                                </div>

                                {canShowCareHomeDetails || enteredManually ?
                                    <div>
                                        <h3>
                                            Care home details
                                        </h3>

                                        {noAddressDetailsFound ?
                                            <span className="nhsuk-error-message" id="CareHomeAddressError">
                                                <span className="nhsuk-u-visually-hidden">Error:</span> Care home address details not found
                                            </span> : null
                                        }

                                        {!enteredManually ?
                                            <>
                                                <NhsAutocompleteInput label={'Name'}
                                                    aria-label="Enter 3 or more characters to search" 
                                                    hint={'Enter 3 or more characters to search'}
                                                    name="CareHomeName"
                                                    noMatchingRecord={noMatchingRecord}
                                                    selectFromDropDown={null}
                                                    formik={formik}
                                                    handleCustomChange={handleCustomChange} />
                                                <NhsAutocompleteSuggestions inputValue={formik.values.CareHomeName} name='CareHomeName' suggestions={suggestions} handleOnClick={handleSuggestionClick} />
                                            </>
                                            :
                                            <>
                                                {
                                                    formik.touched.CareHomeName && formik.errors.CareHomeName ?
                                                        <span className="nhsuk-error-message" id="CareHomeNameError">
                                                            <span className="nhsuk-u-visually-hidden">Error:</span> {formik.errors.CareHomeName}
                                                        </span> : null
                                                }
                                                <div className="nhsuk-form-group">
                                                    <label className="nhsuk-label" htmlFor="CareHomeName">
                                                        Name
                                                    </label>
                                                    <input className="nhsuk-input"
                                                        value={formik.values.CareHomeName}
                                                        onBlur={formik.handleBlur}
                                                        id="CareHomeName"
                                                        name="CareHomeName"
                                                        type="text"
                                                        aria-label="care home name"
                                                        onChange={formik.handleChange} />
                                                </div>
                                            </>
                                        }

                                        {nhsdOrganisation || enteredManually ?
                                            <div>
                                                {
                                                    formik.touched.CareHomeOdsCode && formik.errors.CareHomeOdsCode ?
                                                        <span className="nhsuk-error-message" id="CareHomeOdsCodeError">
                                                            <span className="nhsuk-u-visually-hidden">Error:</span> {formik.errors.CareHomeOdsCode}
                                                        </span> : null
                                                }
                                                <div className="nhsuk-form-group">
                                                    <label className="nhsuk-label" htmlFor="CareHomeOdsCode">
                                                        ODS Code
                                                    </label>
                                                    <input className="nhsuk-input nhsuk-input--width-10"
                                                        disabled={!enteredManually}
                                                        aria-disabled={!enteredManually}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.CareHomeOdsCode}
                                                        id="CareHomeOdsCode"
                                                        name="CareHomeOdsCode"
                                                        onChange={formik.handleChange}
                                                        aria-label="care home ods code"
                                                        type="text" />
                                                </div>

                                                {
                                                    formik.touched.CareHomeAddress && formik.errors.CareHomeAddress ?
                                                        <span className="nhsuk-error-message" id="CareHomeAddressError">
                                                            <span className="nhsuk-u-visually-hidden">Error:</span> {formik.errors.CareHomeAddress}
                                                        </span> : null
                                                }
                                                <div className="nhsuk-form-group">
                                                    <label className="nhsuk-label" htmlFor="CareHomeAddress">
                                                        Address
                                                    </label>
                                                    <input className="nhsuk-input"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        aria-label="care home address"
                                                        aria-disabled={!enteredManually}
                                                        disabled={!enteredManually}
                                                        value={formik.values.CareHomeAddress}
                                                        id="CareHomeAddress"
                                                        name="CareHomeAddress"
                                                        type="text" />
                                                </div>

                                                {
                                                    formik.touched.CareHomePostcode && formik.errors.CareHomePostcode ?
                                                        <span className="nhsuk-error-message" id="CareHomePostcodeError">
                                                            <span className="nhsuk-u-visually-hidden">Error:</span> {formik.errors.CareHomePostcode}
                                                        </span> : null
                                                }
                                                <div className="nhsuk-form-group">
                                                    <label className="nhsuk-label" htmlFor="CareHomePostcode">
                                                        Postcode
                                                    </label>
                                                    <input className="nhsuk-input nhsuk-input--width-10"
                                                        disabled={!enteredManually}
                                                        aria-disabled={!enteredManually}
                                                        value={formik.values.CareHomePostcode}
                                                        id="CareHomePostcode" 
                                                        name="CareHomePostcode"
                                                        type="text"
                                                        maxLength={8}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        aria-label="care home post code" />
                                                </div>
                                            </div>
                                            :
                                            ''
                                        }

                                    </div>
                                    :
                                    ''
                                }

                                {canShowCareHomeDetails ?
                                    <div className="nhsuk-form-group">
                                        <div className="nhsuk-checkboxes">
                                            <div className="nhsuk-checkboxes__item">
                                                <input className="nhsuk-checkboxes__input"
                                                    id="DetailsEnteredManually"
                                                    name="DetailsEnteredManually"
                                                    type="checkbox"
                                                    value="true"
                                                    checked={formik.values.DetailsEnteredManually === true}
                                                    onChange={(e) => { onDetailsEnteredManuallyChange(e); formik.handleChange(e);  }}
                                                    aria-label="checkbox to enter care home details manually" />

                                                <label className="nhsuk-label nhsuk-checkboxes__label"
                                                    htmlFor="DetailsEnteredManually">
                                                    Enter details manually
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }

                                <Button type='submit' className='nhsuk-button float-end' data-module="nhsuk-button">Continue</Button>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </>
        </>
    );
}