import React, {  } from 'react';

export default function NhsErrorSummary({form, activeFields}) {
  let touchedFieldNumber = 0
  const errors = [];
  activeFields.forEach(field => {
    const err = form.errors[field];
    if (err) errors.push(err);
    if (form.getFieldMeta(field)?.touched) touchedFieldNumber ++;
  });
  return (
    <>
      { (errors?.length && touchedFieldNumber === activeFields.length) ? 
        <div className="nhsuk-error-summary" aria-labelledby="error-summary-title" role="alert">
          <h2 className="nhsuk-error-summary__title" id="error-summary-title">
            There is a problem
          </h2>
          <div className="nhsuk-error-summary__body">
            <ul className="nhsuk-list nhsuk-error-summary__list">
              {
                errors.map((e, i) => 
                    <li className="nhsuk-error-message" key={i}>{ e }</li>
                )
              }
            </ul>
          </div>
        </div>
        :
        <div></div>
      }
    </>
  )
}