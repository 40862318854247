import useDocumentTitle from '../../../_shared/hooks/useDocumentTitle';
import { Category } from '../../analytics/analytics.models';
import useAnalytics from '../../analytics/hooks/useAnalytics';
import { VaccinationAnalyticsSubCategory1s, VaccinationTitles } from '../vaccination.enums';

const vaccinationTitles = [
    VaccinationTitles.Vaccine,
    VaccinationTitles.Assessment,
    VaccinationTitles.Consent,
    VaccinationTitles.Vaccinate,
    VaccinationTitles.Confirm
];

const vaccinationAnalyticsSubCategory1s = [
    VaccinationAnalyticsSubCategory1s.Vaccine,
    VaccinationAnalyticsSubCategory1s.Assessment,
    VaccinationAnalyticsSubCategory1s.Consent,
    VaccinationAnalyticsSubCategory1s.Vaccinate,
    VaccinationAnalyticsSubCategory1s.Confirm
];

export default function useVaccinationTitle(step, capitalisedPageType) {
    const vaccination = 'vaccination'
    const primaryCategory = capitalisedPageType.toLowerCase() + "-" + vaccination;

    const subCategory1 = vaccinationAnalyticsSubCategory1s[step];

    useAnalytics({
        primaryCategory: primaryCategory,
        subCategory1: subCategory1
    } as Category);

    useDocumentTitle(vaccinationTitles[step] + " - " + capitalisedPageType + " " + vaccination);

    return { vaccinationTitles, stepTitle: vaccinationTitles[step] }
}