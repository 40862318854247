import React from 'react';
import { Link } from 'react-router-dom';
import useAnalytics from './analytics/hooks/useAnalytics';
import useDocumentTitle from '../_shared/hooks/useDocumentTitle';
import { Category } from './analytics/analytics.models';

export default function PageUnderDev({ analyticsPrimarycategory, pageTitle }) {
    useAnalytics({ primaryCategory: analyticsPrimarycategory } as Category);
    useDocumentTitle(pageTitle);
    return (
        <div className="page-wrap d-flex flex-row align-items-center">

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center mt-5">
                        <div className="mb-4 lead">The page you are looking for is under development.</div>
                        <Link to={'/home'} className="btn btn-link">Back to Home</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
